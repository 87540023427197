import React  from 'react';
import styled from '@emotion/styled';

import CallToAction
                         from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import Heading           from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer            from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper           from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Separator         from '@interness/web-core/src/components/structure/Separator/Separator';
import LiveAnnouncements from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';
import HeaderImage       from '@interness/web-core/src/components/media/HeaderImage/HeaderImage';
import HeaderMedia       from '@interness/theme-default/src/components/HeaderMedia';
import Accordion         from '@interness/web-core/src/components/structure/Accordion/Accordion';

const ColP = styled.p`
  column-count: 3;
  column-gap: 2rem;
  @media all and (max-width: 960px) {
    column-count: unset;
  }
`;

const information = [
  {
    title: 'Terminvergabe',
    text: `
      <p>Um eine optimale Betreuung unserer Patienten zu ermöglichen und Wartezeiten möglichst zu vermeiden, führen wir eine gut organisierte Terminpraxis. Zur Erleichterung dieser Planungen vereinbaren Sie bitte frühzeitig für alle Behandlungen einen Termin beim Arzt Ihrer Wahl. Wir sind bemüht, Ihnen einen Termin nach Ihren Wünschen und unseren Möglichkeiten zu geben.</p>
      <p>Im akuten Krankheitsfall ist ein Termin oft auch kurzfristig möglich! Rufen Sie frühzeitig ab 8 Uhr an, damit wir uns auf Sie einstellen können und Sie noch am gleichen Tag untersucht und behandelt werden. Wir sind ein Team von 3 Ärzt(inn)en. Im akuten Fall ist einer der Ärzte für Sie da.</p>
    `
  },
  {
    title: 'Rezepte / Überweisungen',
    text: `
      <p>Rezepte und Überweisungen können Sie persönlich, telefonisch oder per E-Mail in der Praxis vorbestellen. Hierbei handelt es sich um Wiederverordnungen von sogenannten Dauermedikamenten, also solchen, die über längere Zeit eingenommen werden nach einem festen Plan, der Ihnen in aller Regel in der Praxis ausgehändigt wurde. Ihr Rezept können Sie am Folgetag abholen.</p>
      <p>Bitte bedenken Sie, dass zum Quartalsanfang zunächst Ihre Krankenversicherungskarte vorgelegt und eingelesen werden muss.</p>
      <p>Sie können persönlich nicht in die Praxis kommen? Hinterlegen Sie einen selbstadressierten, frankierten Briefumschlag an der Anmeldung, wenn Sie die Zusendung eines Rezeptes wünschen (oder senden Sie uns den Umschlag zu).</p>
    `
  },
  {
    title: 'Hausbesuche',
    text: `
      <p>Hausbesuche führen wir als Hausärzte durch, wenn die akute medizinische Situation dies erfordert oder eine Beeinträchtigung des Allgemeinzustandes das Aufsuchen der Praxis zeitweilig oder dauerhaft verhindert. Melden Sie sich bitte frühzeitig, wenn ihr Gesundheitszustand einen Hausbesuch erforderlich macht!</p>
      <p>Hausbesuche sind zeitintensiv und bedeuten einen hohen Arbeitsaufwand. So wie Sie darauf vertrauen können, dass wir zu Ihnen kommen, möchten wir darauf vertrauen können, dass Hausbesuche nur bei medizinischer Notwendigkeit angefordert werden.</p>
      <p>Der hausärztliche Notdienst wird zentral gesteuert und ist außerhalb unserer Sprechzeiten zu erreichen unter der bundesweiten <b>Notrufnummer 116 117.</b></p>
      <b>In lebensbedrohlichen Fällen rufen Sie bitte sofort den Rettungsdienst/Feuerwehr unter: 112</b>
    `
  }
]

function IndexPage(props) {
  return (
    <>
      <div style={{ background: '#d4ebf6' }}>
        <p style={{
          marginBottom: '0',
          maxWidth: '1024px',
          margin: 'auto',
          fontSize: '0.8rem',
          padding: '5px 0'
        }}>Fachärzte für Allgemeinmedizin - Chirotherapie - Flugmedizin - Sportmedizin - Tauchuntersuchungen -
          Reisemedizin - Psychosomatische Grundversorgung Tel. 0212 / 20 39 63</p>
      </div>
      <HeaderImage>
        <HeaderMedia id={'team-neu-resize'}/>
      </HeaderImage>
      <Wrapper>
        <Spacer/>
        <section>
          <Heading subtitle={'Herzlich Willkommen'}>Hausarztpraxis Mummstraße</Heading>
          <span style={{ textAlign: 'justify' }}>
            <h3 style={{ textAlign: 'center' }}>Wir behandeln Menschen - keine Krankheiten</h3>
            <ColP>Schön, dass Sie uns besuchen.  Viel Spaß auf unserer Homepage wünscht Ihnen unser ganzes Team, und wir freuen uns auf Ihren Besuch in unserer Praxis.<br/><br/>
              Zielsetzung unserer Arbeit ist die Erhaltung und Wiederherstellung Ihrer Gesundheit, sowie eine wie eine regelmäßige Vorsorge und Beratung möglichst auf ganzheitlicher Grundlage.<br/><br/>
              Dabei bemühen wir uns um eine sorgfältige, gründliche und schnelle Lösung Ihrer gesundheitlichen Probleme - möglichst ohne wesentliche Wartezeit. Wir sind für Sie da.<br/><br/>
              Des Weiteren haben wir wichtige Informationen über unsere Praxisorganisation und unser Leistungsangebot zusammengestellt. Dieses soll es Ihnen ermöglichen sich in unserer Praxis wohl und verstanden zu fühlen.</ColP>
          </span>
          <LiveAnnouncements/>
          <Spacer/>
          <div style={{ background: '#d4ebf6', padding: '20px' }}>
            <h4><b>Was wir für sie verbessert haben:</b></h4>
            <p style={{ marginBottom: '0' }}>Zentral im Zentrum von Solingen - Unsere Praxis ist barrierefrei - Aufzug
              vorhanden - Parkplätze im Innenhof - Bushaltestellen vor der Praxis</p>
          </div>
        </section>
        <Separator/>
        <section>
          <Heading tag='h2'>Wichtige Informationen</Heading>
          <Accordion data={information} />
        </section>
        <Spacer/>
        <div style={{ textAlign: 'center' }}>
          <CallToAction/>
        </div>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default IndexPage;
